<template>
  <div class="header">
    <div class="buttons">
      <div
        class="go-back-arrow"
        v-if="isBackBtnShown"
        @click="$emit('goBack')"
      >
      </div>
      <div
        class="sidebar-btn"
        v-if="isSidebarBtnShown"
        @click="$emit('toggleSidebar')"
      >
      </div>
    </div>
    <div class="logo"></div>
  </div>  
</template>

<script>
export default {
  props: [
    'isBackBtnShown',
    'isSidebarBtnShown',
  ],
}
</script>

<style lang="scss" scoped>
.header {
  width: 100%;
  height: 64px;
  background-color: #13141F;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;

  .buttons {
    position: absolute;
    left: 24px;
    top: 20px;
    display: flex;

    .go-back-arrow {
      background-image: url('../../assets/icons/go-back-arrow.svg');
      width: 24px;
      height: 24px;
      margin-right: 20px;
      background-size: 24px;
      background-position: center;
      background-repeat: no-repeat;
      cursor: pointer;
    }

    .sidebar-btn {
      position: relative;
      width: 24px;
      height: 24px;
      background-size: 24px;
      background-position: center;
      background-repeat: no-repeat;
      background-image: url('../../assets/icons/hamburger-icon.svg');
      cursor: pointer;
    }
  }

  .logo {
    width: 100px;
    height: 55px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 95px 44px;
    background-image: url('../../assets/images/simple_sales_logo_white.png');
  }
}
</style>
